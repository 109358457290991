/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Azi Portfolio",
  description:
    "I'm a software developer experienced in Android app and website development. Published apps on Google Play Store and built websites independently. Proficient in various technologies and deployment methods. Committed to excellence in every project.",
  og: {
    title: "Azi Juan Fahri Portfolio",
    type: "website",
    url: "http://azijuanfahri.com/",
  },
};

//Home Page
const greeting = {
  title: "Azi Juan Fahri",
  logo_name: "",
  nickname: "ajf",
  subTitle:
    "I'm a software developer experienced in Android app and website development. Published apps on Google Play Store and built websites independently. Proficient in various technologies and deployment methods. Committed to excellence in every project.",
  // "I am a software developer with extensive experience in mobile application and website development. I have successfully published Android applications on the Google Play Store and independently developed websites. With proficiency in various programming languages and technologies, as well as experience deploying applications using services like AWS and managing servers using VPS through Docker, I am committed to making maximum contributions to every project I undertake.",
  resumeLink:
    "https://drive.google.com/file/d/1u4Rg7BXSQvBr759YBh3bGhuUzWcmHgsR/view?usp=sharing",
  portfolio_repository: "",
  githubProfile: "https://gitlab.com/azijuanfahri",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/ajfpay",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Gitlab",
    link: "https://gitlab.com/azijuanfahri",
    fontAwesomeIcon: "fa-brands fa-gitlab", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#f76920", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:ajfpay@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  {
    name: "Instagram",
    link: "https://www.instagram.com/azijuanfahri/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Kotlin, and solo android apps using Kotlin",
        "⚡ Creating application backend in python, django & other",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "vscode-icons:file-type-node",
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#4f2b8d",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "vscode-icons:file-type-python",
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "vscode-icons:file-type-django",
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on AWS",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Kementerian Komunikasi dan Informatika",
      iconifyClassname: "simple-icons:android",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://digitalent.kominfo.go.id/",
    },
    {
      siteName: "Dicoding",
      iconifyClassname: "ic:outline-developer-mode",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.dicoding.com/users/azi_juan_fahri/academies",
    },
    {
      siteName: "Google",
      iconifyClassname: "logos:google",
      style: {
        color: "",
      },
      profileLink:
        "https://www.coursera.org/user/fc0d61b7ebd280100c0fe3f948e937a1",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Universitas Islam Negeri Sultan Syarif Kasim Riau",
      subtitle: "Information System",
      logo_path: "lambang-uin-suska.png",
      alt_name: "UIN Suska",
      duration: "2012 - 2019",
      descriptions: [
        "⚡ I have studied basic developer subjects like PHP, Java, DBMS, Data Structure, Object Oriented Design, OS, etc.",
        "⚡ During my studies, I was also active as an independent mobile application developer, focusing mainly on user interface and user experience (UI/UX) design, as well as developing applications intended to be published on the Playstore.",
        "⚡ Indeed, my study period took quite a long time, but this was due to my commitment to work as a independent during my studies. Although this extended the duration of my studies, I believe this work experience has given me practical insights and valuable skills that I could not get from academic studies alone also achieve significant monthly earnings.",
      ],
      website_link: "https://www.uin-suska.ac.id",
    },
    {
      title: "SMK Negeri 1 Rantau Utara",
      subtitle: "Computer Network Engineering",
      logo_path: "tut-wuri-handayani-logo.png",
      alt_name: "Smeneg",
      duration: "2009 - 2012",
      descriptions: [
        "⚡ During my first and second years of study, I elected to specialize in software engineering. I achieved the highest grades in my class, and indeed across the entire year group, for developing an application using Visual Basic.",
        "⚡ Lastly, in my final year of study, I was ranked among the top three due to my passion for programing ",
      ],
      website_link: "",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Android Developer Intermediate",
      subtitle: "- Digitalent Professional Academy",
      logo_path: "kominfo-logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1pdaeSWDy1LsLhPTah_NlgdVkITG9sHqF/view?usp=share_link",
      alt_name: "KOMINFO",
      color_code: "#ffff",
    },
    {
      title: "Android Jetpack Pro",
      subtitle: "- Dicoding",
      logo_path: "dicodingxgoogle.png",
      certificate_link: "https://www.dicoding.com/certificates/1OP8LJ8EVZQK",
      alt_name: "dicoding",
      color_code: "#ffff",
    },
    {
      title: "IT Support",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://coursera.org/share/96c4f1f6d23d4ef2930e4281995ccebc",
      alt_name: "Google",
      color_code: "#ffff",
    },
    {
      title: "Front-End Web Developer Expert",
      subtitle: "- Dicoding",
      logo_path: "dicodingxgoogle.png",
      certificate_link: "https://www.dicoding.com/certificates/0LZ0QN523Z65",
      alt_name: "Dicoding",
      color_code: "#ffff",
    },
    {
      title: "React Native Mobile Apps Development",
      subtitle: "- Sanbercode",
      logo_path: "sanbercode.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1u4TTD-Q_j2GvYGa9DyHqOS93usbTbLmc/view?usp=share_link",
      alt_name: "Sanbercode",
      color_code: "#ffff",
    },
    {
      title: "Fundamental Aplikasi Android",
      subtitle: "- Dicoding",
      logo_path: "dicodingxgoogle.png",
      certificate_link: "https://www.dicoding.com/certificates/W0LZ0WEJ0Z65",
      alt_name: "Dicoding",
      color_code: "#1F70C199",
    },
    {
      title: "Networking Fundamentals",
      subtitle: "- Microsoft Virtual Academy",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1SQy56K3WpuZ1kAmlWVZ2iXT5xwQgouHQ/view?usp=sharing",
      alt_name: "Microsoft",
      color_code: "#ffff",
    },
    {
      title: "Javascript",
      subtitle: "- Progate",
      logo_path: "progate.png",
      certificate_link: "https://progate.com/course_certificate/61011d0dqlh2p0",
      alt_name: "Progate",
      color_code: "#ffff",
    },
    {
      title: "Fundamental Front-End Web Development",
      subtitle: "- Dicoding",
      logo_path: "dicodingxgoogle.png",
      certificate_link: "https://www.dicoding.com/certificates/EYX4R02Y6XDL",
      alt_name: "Dicoding",
      color_code: "#ffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Internship",
  description:
    "A dedicated developer with strong analytical skills. I always strive to improve my skills and keep up with the latest developments in the technology industry. I am ready to face new challenges and contribute to the development of innovative solutions.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Web Developer",
          company: "PT Shield On Service Tbk.",
          company_url: "https://www.sos.co.id",
          logo_path: "developerActivity.svg",
          duration: "June 2024 - Now",
          location: "Jakarta, Indonesia",
          description:
            "In my current role at PT Shield On Service, I am responsible for the full lifecycle of web development projects. From designing user-centric interfaces with Figma to building responsive frontends with React and developing robust backend systems, I ensure seamless functionality across platforms. My experience with Docker-based deployment on VPS environments has honed my skills in maintaining performance, security, and scalability—key elements that I believe are essential to drive success in your development team.",
          color: "#0879bf",
        },
        {
          title: "UI/UX Designer & Android Application Developer",
          company: "KariRise:Persiapan BUMN & CPNS",
          company_url:
            "https://play.google.com/store/apps/details?id=com.keepwi.karirisebumncpns",
          logo_path: "developerActivity.svg",
          duration: "January 2024 - Now",
          location: "Pekanbaru, Riau",
          description:
            "I am an accomplished professional specializing in Android application development, serving dual roles as a UI/UX Designer and Flutter Developer. My responsibilities encompass crafting visually appealing and user-centric interfaces, implementing them using Flutter framework. Additionally, I manage the technical backend aspects of applications, leveraging Docker for containerization and VPS rental to deploy necessary API services. With a steadfast commitment to quality, performance, and innovation, I ensure that each project I undertake not only boasts captivating design but also delivers seamless user experiences and efficient backend infrastructure",
          color: "#0879bf",
        },
        {
          title: "Web and Android Application Developer",
          company: "Teknohorizon.com",
          company_url: "teknohorizon.com",
          logo_path: "developerActivity.svg",
          duration: "March 2023 - Now",
          location: "Pekanbaru, Riau",
          description:
            "As a Web and Android Application Developer at Teknohorizon, I am entrusted with the design, development, and maintenance of our digital platforms. In this capacity, I oversee the creation of Android applications and websites, prioritizing optimal user experiences while managing the full software development lifecycle. Committed to innovation and quality, I dedicate myself to delivering sophisticated technological solutions and continually enhancing my skills to tackle complex technical challenges with proficiency.",
          color: "#0879bf",
        },
        {
          title: "Independent App & Game Developer",
          company: "Archive Of Our Own (Independent Work)",
          company_url: "",
          logo_path: "developerActivity.svg",
          duration: "January 2018 - November 2022",
          location: "Pekanbaru, Riau",
          description:
            "As an Android developer, I have honed my skills in Kotlin and Jetpack Compose, implementing suitable architecture for applications. I also have experience in web development using React for frontend development. Additionally, I have developed backend systems using Python, PostgreSQL, and Django, integrated with Django REST Framework for seamless connection with Android applications. I have also gained experience in managing infrastructure using AWS services.",
          color: "#0879bf",
        },
        {
          title: "IT Support",
          company: "PT. PLN (Persero) Area Kota Pekanbaru",
          company_url: "https://web.pln.co.id/",
          logo_path: "pln_logo.png",
          duration: "March 2017 - July 2017",
          location: "Pekanbaru, Riau",
          description:
            "As an IT Support at PT. PLN, I ensured the proper functioning and connectivity of computer systems. I regularly updated and maintained operating systems and applications used by users. I also performed data backup to ensure the safety and recoverability of important data. Additionally, I managed supporting devices such as printers and scanners.",
          color: "#ffd002",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "picture-profile.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Android Kotlin, React Website, Javascript, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://teknohorizon.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Jalan Rambutan, Kota Pekanbaru, Riau",
    locality: "Pekanbaru",
    country: "ID",
    region: "Riau",
    postalCode: "28294",
    streetAddress: "Street Rambutan",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/yF8A78gAGUEsyKqZ6",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
